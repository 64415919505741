import * as React from "react"
import { Link } from "gatsby"
import "../styles/main.scss";

export default function NotFoundPage () {
  return (
    <main>
      <h1>Seite nicht gefunden</h1>
      <p>
        Sorry 😔, diese Seite gibt es leider nicht.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">ᐊ zur Startseite</Link>
      </p>
    </main>
  )
}
